/*
   -----------------------------------------------------
     jQuery Custom Cursor v1.0   	 
	 © 2020 Boom Apps

	 This is licenced software, you can purchase licence here: https://codecanyon.net/user/boom-apps/portfolio
	 
   -----------------------------------------------------
*/

body.CustomCursor,
body.CustomCursor * {
  cursor: none !important;
}
@media (max-width: 1024px) {
  body.CustomCursor,
  body.CustomCursor * {
    cursor: auto !important;
  }
  body.CustomCursor .Cursor {
    display: none !important;
  }
}

body.CustomCursor .Cursor {
  display: none;
  pointer-events: none;
}
body.CustomCursor .Cursor.MouseMove {
  display: block;
}
body.CustomCursor .Cursor > span.First {
  z-index: 9999;
  width: 30px;
  height: 30px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}
body.CustomCursor .Cursor > span.First i {
  display: block;
  transition: 0.3s background ease, 0.3s transform ease, 0.3s border ease;
  width: 6px;
  height: 6px;
  margin: 12px;
  pointer-events: none;
  border-radius: 100%;
  background: #ff1493;
  box-sizing: border-box;
  border-radius: 100%;
  opacity: 1;
}
body.CustomCursor .Cursor > span.Second {
  z-index: 9998;
  width: 30px;
  height: 30px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}
body.CustomCursor .Cursor > span.Second i {
  display: block;
  transition: 0.3s background ease, 0.3s transform ease, 0.3s border ease,
    0.3s opacity ease;
  width: 30px;
  height: 30px;
  pointer-events: none;
  border-radius: 100%;
  background: #31acff;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0.5;
}
body.CustomCursor .Cursor.Hover > span.Second i {
  background: #31acff;
  opacity: 0.25;
  transform: scale(3.5);
  border-width: 1px;
}
body.CustomCursor .Cursor > span.Second img {
  display: block;
  transition: 0.3s background ease, 0.3s transform ease, 0.3s border ease,
    0.3s opacity ease;
  width: 30px;
  height: 30px;
  pointer-events: none;
  border-radius: 100%;
  background: #31acff;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0.5;
}
body.CustomCursor .Cursor.Hover > span.Second img {
  background: #31acff;
  opacity: 0.25;
  transform: scale(3.5);
  border-width: 1px;
}
