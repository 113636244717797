@import "./normalize.css";
@import "./magnifier.css";
@import "./jquery.customcursor.4.css";
@import "./blog_page.css";

:root {
  --color-primary: #ff1493;
  --color-secondary: #0e004a;
  --color-accent: #ad0052;
  --color-headings: #31acff;
  --color-body: #1b0096;
  --color-body-darker: #ff95f0;
  --color-border: #31acff;
  --border-radius: 70px;
}
/* ------------------------------------------------------------------------------
  1.  Global
      1.1 Reset styles
      1.2 Fonts
      1.3 General
  2.  Header
  3.  Main
      3.1 Home 
      3.2 Blog page

-------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------
  1. Global
-------------------------------------------------------------------------------*/
/* 1.1 Reset styles */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b,
strong {
  font-weight: 700;
}

i {
  font-style: italic;
}

.primary {
  color: #ff1493;
}
.primary2 {
  color: #fff;
}
.secondary {
  color: var(--color-headings);
}
.webgl {
  z-index: 11;
  position: fixed;
  right: 0;
  outline: none;
  mix-blend-mode: soft-light;
  overflow: hidden !important;
  height: 100%;
  width: 100% !important;
}

.left-bottom {
  position: relative;
  height: 100vh;
}
.babes {
  height: 5vh;
  position: absolute;
  left: 70%;
  bottom: 3.8vh;
}

.babes img {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 960px) and (orientation: portrait) {
  .babes {
    display: none;
  }
  .left-bottom {
    display: none;
  }
}

.wave * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wave a {
  position: relative;
  padding: 2vh 1.5vh;
  display: block !important;
  text-decoration: none;
  text-transform: uppercase;
  width: 10.4vw;
  overflow: hidden;
  font-size: 0.92917vw;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}
.wave a span {
  position: relative;
  color: #fff;
  z-index: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
a .liquid {
  position: absolute;
  top: -95px;
  left: 0;
  width: 10.4vw;
  height: 25vh;
  background-color: #bb0968;
  box-shadow: outset 0 0 50px rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
a .liquid::after,
a .liquid::before {
  content: "";
  width: 230%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
  transform: translate(-50%, -75%);
  box-shadow: inset 0 0 10px rgba(83, 0, 116, 0.356) !important;
  background-color: #6e329650 !important;
}
a .liquid::before {
  border-radius: 100% !important;
  background-color: rgba(106, 19, 133, 0.473) !important;
  -webkit-animation: animate 15s linear infinite;
  animation: animate 15s linear infinite;
}
a .liquid::after {
  border-radius: 40% !important;
  background-color: rgba(73, 37, 87, 0.5) !important;
  -webkit-animation: animate 15s linear infinite;
  animation: animate 15s linear infinite;
}
a:hover .liquid {
  top: -140px;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .wave a {
    font-size: 3.92917vw;
    width: 40vw;
  }
  a .liquid {
    top: -105px;
    width: 40vw;
    height: 25vh;
  }
  a .liquid::after,
  a .liquid::before {
    width: 170%;
    height: 170%;
    top: 29%;
    left: 50%;
  }
}
/* 01. CSS3 Liquid Button Animation */

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -75%) rotate(180deg);
    transform: translate(-50%, -75%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -75%) rotate(360deg);
    transform: translate(-50%, -75%) rotate(360deg);
  }
  50% {
    -webkit-transform: translate(-50%, -75%) rotate(180deg);
    transform: translate(-50%, -75%) rotate(180deg);
  }
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -75%) rotate(180deg);
    transform: translate(-50%, -75%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -75%) rotate(360deg);
    transform: translate(-50%, -75%) rotate(360deg);
  }
  50% {
    -webkit-transform: translate(-50%, -75%) rotate(180deg);
    transform: translate(-50%, -75%) rotate(180deg);
  }
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
}

@font-face {
  font-family: "RetroSignature";
  src: url("https://www.dropbox.com/s/py6jcc7f8c3jz7o/RetroSignature.otf?dl=0")
      format("woff"),
    url("https://www.dropbox.com/s/isy22i76h6brt8q/RetroSignature_1.ttf?dl=0")
      format("truetype");
  src: url("../fonts/RetroSignature.otf") format("woff"),
    url("../fonts/RetroSignature_1.ttf") format("truetype");
  font-weight: bolder;
}

/* 1.3 General */
html,
body {
  max-width: 100%;
  height: 100%;
}

html {
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-locale: auto;
  font-family: futura-pt, Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--color-headings);
}

input,
button {
  -webkit-appearance: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: var(--color-headings);
}
a:hover {
  color: var(--color-body-darker);
}

ol,
ul,
nav {
  list-style: none;
}

img {
  display: block;
}

*:focus {
  outline: none;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 5px;
  background: var(--color-body-darker);
}

body::-webkit-scrollbar-thumb {
  background: var(--color-accent);
}

.title {
  font-family: RetroSignature !important;
  text-transform: none;
}
h1,
h2,
h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h2 {
  font-size: 2vw;
}

.main {
  width: 100%;
  min-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .main--home {
    opacity: 1 !important;
  }
}

.noise {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0.15;
}
.noise--inner {
  width: 100%;
  height: 100%;
  position: absolute;
}

.btn {
  border: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 70px !important;
  font-size: 0.9375vw;
  line-height: 110%;
  text-transform: uppercase;
  text-align: center;
  padding: 0.92917vw 3.125vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .btn {
    border-radius: 13.33333vw;
    font-size: 4.8vw;
    padding: 4.73333vw 22vw;
  }
}
.btn:hover {
  color: var(--color-body);
}
.btn svg {
  max-width: 1.875vw;
  max-height: 0.83333vw;
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .btn svg {
    max-width: 9.6vw;
    max-height: 4.26667vw;
  }
}
.btn--arrow {
  padding: 1.35417vw 1.875vw;
  color: white;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .btn--arrow {
    padding: 6.93333vw 9.6vw;
  }
}
.btn--invert {
  background: transparent;
  border: 1px solid var(--color-border);
}

.h1 {
  font-weight: 500;
  font-size: 3.95833vw;
  line-height: 0.91;
  text-transform: uppercase;
  white-space: pre;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .h1 {
    font-size: 11.73333vw;
  }
}

.small {
  font-size: 0.88125vw;
  line-height: 130%;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .small {
    font-size: 4vw;
  }
}

.input-file input {
  display: none;
}

.input-file span {
  cursor: pointer;
  text-decoration: underline;
}

/*-------------------------------------------------------------------------------
  2. Header
-------------------------------------------------------------------------------*/
.header {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*border-bottom: 1px solid rgba(230, 227, 217, 0.3);*/
  height: 4.63542vw;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 50;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header {
    height: 18.66667vw;
    background: linear-gradient(to top, #4a0853 0%, #0e004a 90%);
    z-index: 100;
  }
}
.header__left {
  width: 36.927vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*border-right: 1px solid rgba(230, 227, 217, 0.3);*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header__left {
    width: 60%;
    border-right: 0;
  }
}
.header__right {
  width: calc(100% - 36.927vw);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 1.66667vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header__right {
    width: 40%;
    padding-right: 5.33333vw;
  }
}
.header__letter {
  z-index: 0;
  padding-top: 1rem;
  padding-left: 2rem;
  width: 3.27083vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.97917vw;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header__letter {
    width: 50%;
    padding-left: 0px;
    padding-top: 1.5px;
  }
  .header__letter img {
    width: 7vw;
  }
}
.header__menu {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header__menu {
    display: none;
  }
}
.header__menu li {
  display: block;
  padding: 0 1.96667vw 0 0;
  text-transform: uppercase;
}
.header__menu li a {
  font-size: 0.52917vw;
  line-height: 140%;
  display: block;
}
.header__menu li.active a {
  color: var(--color-primary);
}
.header__menu.blocked li a {
  cursor: default;
  pointer-events: none;
}
.header__phone {
  text-align: right;
}
.header__phone a {
  font-size: 0.78125vw;
  line-height: 130%;
  display: block;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .header__phone a {
    font-size: 4vw;
    text-align: right;
    color: white;
  }
}

/*-------------------------------------------------------------------------------
  3. Main
-------------------------------------------------------------------------------*/
/* 3.1 Home page */
.scroll {
  z-index: 10;
  position: fixed;
  width: 100%;
  background: var(--color-body);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff1491c5),
    color-stop(32%, #31acff),
    to(#0e004a)
  );
  background: linear-gradient(to top, #ff1491c5 -70%, #0e004a 90%);
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .scroll {
    position: static;
    background: var(--color-secondary) !important;
    padding-top: 18.66667vw;
  }
}

/* .scroll2 {
  background: var(--color-body) !important;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#31acff),
    color-stop(32%, #31acff),
    to(#0e004a)
  ) !important;
  background: linear-gradient(to top, #31acff -70%, #0e004a 90%) !important;
} */

/* @media screen and (max-width: 768px) and (orientation: portrait) {
  .scroll2 {
    position: static;
    background: var(--color-secondary) !important;
    padding-top: 18.66667vw;
  }
} */

.page-title,
.sticky-title {
  overflow: hidden;
  width: 4.27083vw;
  height: 100%;
  position: fixed;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 10;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  /*border-right: 1px solid var(--color-primary);*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .page-title,
  .sticky-title {
    display: block;
    width: 100%;
    height: 17.33333vw;
    position: sticky;
    top: 18.66667vw;
    -webkit-transform: none;
    transform: none;
    overflow: hidden;
  }
}

@media screen and (min-width: 1024px) {
  .sticky-title {
    display: none;
  }
}
.page-title.show,
.sticky-title.show {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.page-title .number,
.page-title .title,
.sticky-title .number,
.sticky-title .title {
  display: block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.page-title ul,
.sticky-title ul {
  text-transform: uppercase;
  width: 100vh;
  font-size: 4.97917vw;
  position: absolute;
  bottom: -7vh;
  left: 0;
  height: 4.27083vw;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: rotate(-90deg) translateY(100%) translateX(4.63542vw);
  transform: rotate(-90deg) translateY(100%) translateX(4.63542vw);
  white-space: nowrap;
  z-index: 1;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .page-title ul,
  .sticky-title ul {
    width: 100%;
    font-size: 17.33333vw;
    position: static;
    height: 100%;
    -webkit-transform: none;
    transform: none;
    text-align: center;
  }
}
.page-title ul li,
.sticky-title ul li {
  position: absolute;
  padding: 0 8.66667vw 0 3.82292vw;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .page-title ul li,
  .sticky-title ul li {
    padding: 0 8.33333vw;
    padding-bottom: 10px;
  }
}

/*Left border bg color*/
.page-title {
  background: transparent;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .page-title {
    display: none;
  }
}
.page-title ul li:not(:first-child) {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.sticky-title {
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
  letter-spacing: 0.05em;
  /*border-left: 1px solid rgba(230, 227, 217, 0.3);*/
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .sticky-title {
    border-left: 0;
    background: #4a0853;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }
}
.sticky-title ul {
  -webkit-transform: rotate(-90deg) translateY(100%) translateX(0) !important;
  transform: rotate(-90deg) translateY(100%) translateX(0) !important;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .sticky-title ul {
    -webkit-transform: none !important;
    transform: none !important;
  }
}
.sticky-title span {
  display: inline-block;
}

.home-page {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 4.63542vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 0;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page {
    height: auto;
    position: static;
    padding-top: 0;
    display: block;
  }
}

.home-page .scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 0;
  height: 100%;
  width: 100vw;
  left: 0;
  top: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .scrollable {
    display: block;
    height: auto;
  }
}
.home-page .scrollable > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.home-page section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
}
/*Backgrounds*/
.home {
  background-image: url(../img/Home.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.projects {
  background-image: url(../img/Projects.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.awards {
  background-image: url(../img/Process.png);
  background-size: auto 100vw;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.testimonials {
  background-image: url(../img/Testimonials.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.experience {
  background-image: url(../img/Experience.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.news {
  background-image: url(../img/Pricing.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.contacts {
  background-image: url(../img/Contact.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page section {
    display: block;
    height: auto;
  }
}
.home-page .content {
  width: 100vw;
  height: 100%;
  padding-left: 6.27083vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content {
    display: block;
    width: 100%;
    height: auto;
    padding-left: 0;
  }
}
.home-page .content__bg {
  position: absolute;
  z-index: -2;
  width: calc(100% - 6.27083vw);
  height: 100%;
  background-size: cover;
  right: 0;
  top: 0;
  pointer-events: none;
  opacity: 0.5;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__bg {
    width: 100%;
  }
}
.home-page .content__bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: block;
}
.home-page .content__left {
  width: calc(36.927vw - 6.27083vw);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.14583vw 2.08333vw 1.14583vw 1.14583vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__left {
    width: 100%;
    border-right: 0;
    border-top: 1px solid rgba(226, 200, 220, 0.2);
    padding: 5.33333vw;
    padding-bottom: 50px;
  }
}
.home-page .content__right {
  width: calc(100% - (36.927vw - 6.27083vw));
  padding: 1.14583vw 1.14583vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__right {
    width: 100%;
    padding: 5.33333vw;
  }
}
.home-page .content__title > * {
  position: relative;
}
.home-page .content__description {
  margin-top: 2.5vw;
  max-width: 19.79167vw;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__description {
    max-width: 100%;
    margin-top: 3vh;
  }
  .content__title {
    margin-top: 5vh;
  }
}
.home-page .content__description + .content__btn-block {
  margin-top: 1.46875vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__description + .content__btn-block {
    margin-top: 10.66667vw;
  }
}
.home-page .content__btn-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-start;
  margin-top: 3.90625vw;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__btn-block {
    margin-top: 10.66667vw;
  }
}
.home-page .content__btn-label {
  margin-right: 1.40625vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__btn-label {
    margin-right: 7.2vw;
  }
}
.home-page .content__btn-btn {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.home-page .content__menu {
  list-style: none;
  margin-top: 3.82292vw;
  margin-left: -1.14583vw;
  margin-right: -2.08333vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu {
    margin-top: 2.60417vw;
    margin-left: -5.33333vw;
    margin-right: -5.33333vw;
  }
}
.home-page .content__menu li {
  font-size: 1.14583vw;
  line-height: 160%;
  text-transform: uppercase;
  border-top: 1px solid var(--color-border);
  border-top: 1px solid rgba(226, 200, 220, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  opacity: 0.5;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu li {
    font-size: 4.26667vw;
    opacity: 1;
  }
}
.home-page .content__menu li + li {
  margin-top: -1px;
}
.home-page .content__menu li > span {
  color: inherit;
  padding: 0.46875vw 2.70833vw 0.46875vw 1.14583vw;
  display: block;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu li > span {
    padding: 2.4vw 10.66667vw 2.4vw 5.33333vw;
  }
}
.home-page .content__menu li > a {
  color: inherit;
  padding: 0.46875vw 2.70833vw 0.46875vw 1.14583vw;
  display: block;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu li > a {
    padding: 2.4vw 8.53333vw 2.4vw 5.33333vw;
  }
  .home-page .content__menu li > a:not(.noicon)::after {
    content: "";
    position: absolute;
    right: 0.83333vw;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0.98958vw;
    height: 0.98958vw;
    background: url(../img/arrow-link.svg) center center no-repeat;
    background-size: contain;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu li > a:not(.noicon)::after {
    right: 4.26667vw;
    width: 4.26667vw;
    height: 4.26667vw;
  }
}
.home-page .content__menu li:hover {
  opacity: 0.8;
}
.home-page .content__menu li.active {
  opacity: 1;
}
.home-page .content__menu li.active a:not(.noicon)::after {
  content: "";
  position: absolute;
  right: 0.83333vw;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.98958vw;
  height: 0.98958vw;
  background-size: contain;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__menu li.active a:not(.noicon)::after {
    right: 4.26667vw;
    width: 4.26667vw;
    height: 4.26667vw;
  }
}
.home-page .content__subtitle {
  font-weight: 300;
  font-size: 2.08333vw;
  line-height: 120%;
  max-width: 100%;
  position: relative;
  padding-left: 10vw;
}
.content__slider-text {
  font-size: 1.53333vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__subtitle {
    padding-top: 50px;
    font-size: 5.53333vw;
    max-width: 100%;
  }
  .content__slider-text {
    font-size: 3.53333vw;
  }
}

.hidden {
  display: none;
}
.home-page .content__subtitle span {
  color: var(--color-headings);
}
.home-page .content__awards {
  margin-left: -1.14583vw;
  margin-right: -1.14583vw;
  height: -webkit-fill-available;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 5vh;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__awards {
    margin-left: -5.33333vw;
    margin-right: -5.33333vw;
    margin-top: 5.33333vw;
    display: block;
    height: auto;
    padding-bottom: 50px;
  }
}
.home-page .content__award {
  min-height: 16.19048vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2.09524vh 3.85417vw;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__award {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 4vw 5.33333vw;
    min-height: 0;
  }
}
.home-page .content__award + .content__award {
  border-top: 1px solid rgba(226, 200, 220, 0.2);
}
.home-page .content__award-img {
  width: 4.42708vw;
  height: 4.42708vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -ms-flex-preferred-size: 4.42708vw;
  flex-basis: 4.42708vw;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 3.80208vw;
  z-index: 0;
  margin-left: 5vw;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__award-img {
    width: 100vw;
    -ms-flex-preferred-size: 13.33333vw;
    flex-basis: 13.33333vw;
    margin-left: 0;
    padding-right: 5px;
  }
}
.home-page .content__award-img a {
  padding-left: 1.5vw;
  margin-bottom: 5vh;
  max-width: 150%;
  max-height: 150%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}
.home-page .content__award-img img {
  box-shadow: 0 5px 0px rgba(0, 0, 0, 0.1);
}
.home-page .content__award-img a img {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.home-page .content__award-img a img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.home-page .content__award-title {
  width: 16.14583vw;
  margin-right: 3.02083vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__award-title {
    width: calc(100% - 23vw);
    margin-right: 0;
  }
  .home-page .content__award-img img {
    width: 15vw;
  }
}
.home-page .content__award-title p {
  font-size: 1.14583vw;
  line-height: 130%;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__award-title p {
    font-size: 4.53333vw;
  }
}
.home-page .content__award-title span {
  opacity: 0.5;
}
.home-page .content__award-text {
  width: 20.3125vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__award-text {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 4vw;
    padding-left: 22.13333vw;
  }
}
.home-page .content__contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 3vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__contacts {
    display: block;
    margin-top: 10.66667vw;
  }
}
.home-page .content__brief {
  width: 16.71875vw;
  margin-right: 10.05208vw;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__brief {
    width: 100%;
    margin-right: 0;
  }
}
.home-page .content__brief-title {
  font-size: 0.9375vw;
  line-height: 1.82292vw;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: inherit;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__brief-title {
    font-size: 4.8vw;
    line-height: 9.33333vw;
  }
}
.home-page .content__brief-text {
  margin-top: 1.25vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__brief-text {
    margin-top: 5.33333vw;
  }
}
.home-page .content__brief-text label,
.home-page .content__brief-text span,
.home-page .content__brief-text a {
  color: var(--color-headings);
}
.home-page .content__form {
  width: 35.78125vw;
  margin-left: 11.125vw;
  margin-top: 5.125vw;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form {
    width: 100%;
    margin-top: 3.125vw;
    margin-left: 0;
  }
}
.home-page .content__form-placeholder {
  height: 100%;
  width: 100%;
  font-size: 0.9375vw;
  line-height: 2.60417vw;
  opacity: 0.5;
  text-transform: uppercase;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  top: 0;
  left: 8%;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-placeholder {
    font-size: 4.8vw;
    line-height: 13.33333vw;
  }
}
.home-page .content__form-input {
  height: 2.60417vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-input {
    height: 13.33333vw;
  }
}
.home-page .content__form-input + .content__form-input {
  margin-top: 2.91667vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-input + .content__form-input {
    margin-top: 5.33333vw;
  }
}
.home-page .content__form-input input {
  background: transparent;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  color: inherit;
  border: none;
  border-bottom: 1px solid var(--color-border);
  font-size: 0.9375vw;
  line-height: 1.82292vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-input input {
    font-size: 4.8vw;
    line-height: 9.33333vw;
  }
}
.home-page .content__form-input input.valid,
.home-page .content__form-input input:focus {
  outline: none;
}
.home-page .content__form-input input.valid + .content__form-placeholder,
.home-page .content__form-input input:focus + .content__form-placeholder {
  font-size: 0.67708vw;
  line-height: 0.67708vw;
  top: -0.3125vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-input input.valid + .content__form-placeholder,
  .home-page .content__form-input input:focus + .content__form-placeholder {
    font-size: 3.46667vw;
    line-height: 3.46667vw;
    top: -1.6vw;
  }
}
.content__form-btn {
  margin-left: -3vw;
}
.home-page .content__form-btn {
  margin-top: 4.0625vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__form-btn {
    margin-top: 8vw;
  }
}
.home-page .content__slider {
  width: 100%;
  height: 100%;
  margin-left: 2.65625vw;
  padding-left: 2.41667vw;
  padding-right: 3.41667vw;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__slider {
    margin-left: 0;
    margin-top: -1vh;
    padding-bottom: 50px;
  }
}
.home-page .content__slider::before {
  z-index: -10;
  content: "";
  display: block;
  width: 19.26042vw;
  height: 17.53125vw;
  position: absolute;
  left: 0;
  top: 10vh;
  pointer-events: none;
  background: url("../img/Quote.svg") left top no-repeat;
  background-size: contain;
  opacity: 0.1;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__slider::before {
    width: 12vw;
    height: 8vw;
  }
}
.home-page .content__slider-name {
  margin-top: 4.66667vh;
  font-size: 1.14583vw;
  line-height: 1.59;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__slider-name {
    margin-top: 8vw;
    font-size: 4.8vw;
  }
}
.home-page .content__slider-staff {
  margin-top: 0.41667vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home-page .content__slider-staff {
    margin-top: 2.13333vw;
  }
}

.slides {
  position: relative;
  width: 30.20833vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .slides {
    width: 100%;
  }
}
.slides__container {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 32.38095vh;
  width: 100%;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .slides__container {
    height: 66.66667vw;
  }
}
.slides__inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.slides__controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5625vw;
  justify-content: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .slides__controls {
    margin-top: 5.33333vw;
  }
}
.slides__btn--prev {
  color: var(--color-secondary);
  background-color: white;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.slides__btn--next {
  border: 0.5px solid var(--color-border);
  color: var(--color-secondary);
  background-color: var(--color-primary);
}
.slides__btn + .slides__btn {
  margin-left: 2.41667vw;
}
.slides__btn--prev:hover {
  background-color: transparent;
  color: var(--color-border);
}
.slides__btn--next:hover {
  background-color: transparent;
  color: var(--color-border);
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .slides__btn + .slides__btn {
    margin-left: 5.33333vw;
  }
}
.slides .slide {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}

.home__circles {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__circles {
    position: relative;
    height: 80vw;
  }
}
.home__circles-lines {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.home__circles-lines div {
  width: 1px;
  height: 0;
  background: var(--color-body);
  opacity: 0;
}
.home__circles-lines div:first-child,
.home__circles-lines div:last-child {
  opacity: 0;
}

/*2 circles at home*/
.home__circle {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: flex end;
  justify-content: flex-end;
  position: absolute;
  left: 27%;
  z-index: 0;
  background: transparent;
}
.home__circle img {
  width: 28vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__circle {
    width: 100%;
    height: 100%;
    left: 0;
  }
  .home__circle-img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .home__circle img {
    width: 60%;
  }
}
.home__circle-img {
  align-content: center;
  width: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.home__numbers {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__numbers {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1.5625vw;
  }
}

.home__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__number {
    width: 50%;
  }
}
.home__number-digital {
  font-size: 3.85417vw;
  line-height: 140%;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__number-digital {
    font-size: 13.33333vw;
  }
}
.home__number-text {
  margin-left: 1.35417vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .home__number-text {
    margin-left: 5.33333vw;
  }
}

.projects__blocks {
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-left: 25vh;
}
.projects__block img {
  border-radius: 10px;
  width: 45vw !important;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .projects__blocks {
    display: none;
  }
  .content__menu h2 {
    font-size: 7.33333vw;
    padding-top: 12px;
    padding-left: 60px;
  }
}

.projects__block {
  height: auto;
  overflow: visible;
  display: none;
}
.projects__block + .projects__block {
  padding-top: 2.60417vw;
}
.projects__block img {
  width: 100%;
  opacity: 0.7;
  display: block;
  -webkit-transition: -webkit-transform 5s;
  transition: -webkit-transform 5s;
  transition: transform 5s;
  transition: transform 5s, -webkit-transform 0.3s;
}
.projects__block a {
  display: block;
  overflow: hidden;
}
.projects__block a img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  border-radius: 10px !important;
  opacity: 1;
  -webkit-transition: -webkit-transform 5s;
  transition: -webkit-transform 5s;
  transition: transform 5s;
  transition: transform 5s, -webkit-transform 0.3s;
}
.projects__block.active {
  display: block;
}

.testimonials__clients {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .testimonials__clients {
    margin-top: 13.33333vw;
  }
}

.testimonials__client {
  width: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 11.42857vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.26042vw 0;
  padding: 0 0.26042vw;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .testimonials__client {
    height: 21.33333vw;
    margin: 1.33333vw 0;
    padding: 0 1.33333vw;
  }
}
.testimonials__client img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.news__blocks {
  height: 100%;
  overflow: hidden;
  padding-right: 1.5625vw;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .news__blocks {
    display: none;
  }
}

.news__block {
  height: auto;
  overflow: visible;
  display: none;
  border-bottom: 1px solid rgba(226, 200, 220, 0.2);
}
.news__block + .news__block {
  padding-top: 3.20833vw;
  padding-bottom: 1.20833vh;
}
.news__block img {
  width: 100%;
  display: block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.news__block a {
  display: block;
  width: 100%;
  overflow: hidden;
}
.news__block a img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.news__block-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.86458vw;
}
.news__block-date {
  width: 20.79167vw;
  opacity: 0.9;
  font-size: 3vh;
}
.news__block-text {
  width: 22.3125vw;
}
.news__block-number {
  font-weight: 500;
  font-size: 1.78333vw;
  line-height: 1;
}
.news__block.active {
  display: block;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .news h2 {
    font-size: 3vh;
  }
}

.contacts__copyright {
  margin-top: auto;
  position: relative;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .contacts__copyright {
    margin-top: 20vw;
  }
  .content__award-img a {
    padding: 44px;
  }
}

.contacts__form {
  margin-top: 7.61905vh;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .contacts__form {
    margin-top: 13.33333vw;
  }
  .social {
    height: 10vh !important;
    margin: 0;
  }
  .social img {
    width: 10vw !important;
  }
}

/*# sourceMappingURL=maps/main.css.map */
.glass {
  background-color: #8bbee0e7;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--color-primary);
  z-index: 0;
  width: 105px;
  height: 105px;
  position: fixed;
  border-radius: 100%;
  z-index: 10;
  /* hide the glass by default */
  display: none;
}

.higher {
  margin-bottom: 25vh;
}
/* 01. Responsive Card */
.container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 5vh;
}
.container .card {
  position: relative;
  width: 12vw;
  height: 22vh;
  background-color: rgba(255, 255, 255, 0.589);
  margin: 20px 20px 0 5px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.container:hover .card {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.container .card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background-color: white;
}
.container .card .circle {
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-clip-path: circle(180px at center 0);
  clip-path: circle(180px at center 0);
  text-align: center;
}
.container .card .circle h3 {
  color: var(--color-secondary);
  font-size: 1.35833vw;
  padding: 20px 0;
}
.container .card .content {
  width: 100%;
  position: absolute;
  bottom: -8vh;
  padding: 20px;
  text-align: center;
  justify-content: center;
}
.container .card .content p {
  color: #574a8d;
  font-size: 0.95833vw;
}
.container .card .content a {
  background-color: #000;
  color: rgba(255, 255, 255, 0.486);
  border-radius: 10px;
  text-decoration: none;
  text-transform: capitalize;
}
.container .card:nth-child(1) .circle,
.container .card:nth-child(1) .content a {
  background-color: transparent;
}
.container .card:nth-child(2) .circle,
.container .card:nth-child(2) .content a {
  background-color: transparent;
}
.container .card:nth-child(3) .circle,
.container .card:nth-child(3) .content a {
  background-color: transparent;
}
.container .card:nth-child(4) .circle,
.container .card:nth-child(4) .content a {
  background-color: transparent;
}
.container .card:nth-child(5) .circle,
.container .card:nth-child(5) .content a {
  background-color: transparent;
}

.popular {
  display: relative !important;
  margin-top: -5.5% !important;
  height: 19.42708vh !important;
  margin-left: 0vw !important;
  margin-right: 1vw !important;
}

/*# sourceMappingURL=main.css.map*/

.translate {
  background-color: transparent;
  padding: 0.7vw 0 0.7vw 1.96667vw !important;
  border-radius: 100px;
  /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.13); */
  margin-right: 1vw;
}
.translate li {
  padding-left: 0.4vw;
  padding-right: 0.4vw;
}
.translate li a {
  font-size: 0.7vw;
}
.smaller {
  font-size: 0.5vw;
}
